import '/src/css/app.pcss';
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://0c05b7bdb0fe61235ecdb4c3766655e6@o4507351741956096.ingest.us.sentry.io/4507351798644736",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    /^Uncaught DOMException: Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules$/,
  ]
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

function addMultipleEventListener(element, events, handler) {
  events.forEach(e => element.addEventListener(e, handler));
}

window.addEventListener('load', () => {
  console.log("Bootstrapping 4-H 🐮");

  // addMultipleEventListener(document, ['keyup'], (event) => {
  //   if (event.key === 'Escape') {
  //     menu.setAttribute('aria-expanded', 'false'); 
  //     wrap.classList.remove('menu-open');
  //     tl.reverse()
  //   }
  // });
});

const toggleCSSclasses = (el, ...cls) => cls.map(cl => el.classList.toggle(cl));

// Collect re-used DOM references
const sidebarTrigger = document.querySelector('.mobile-sidebar-modal');
const sidebar = document.querySelector('.sidebar');
const menuToggleButton = document.querySelector('#menu-toggle');
const navbar = document.querySelector('#primary-menu');

// Initialize mobile menu toggle
if (menuToggleButton) {
  menuToggleButton.addEventListener('click', () => {
    menuToggleButton.classList.toggle('is-active');
    toggleCSSclasses(navbar, 'pointer-events-none', 'opacity-0');
    // Hide the sidebar if open
    sidebar.classList.add('pointer-events-none');
    sidebar.classList.add('opacity-0');
  });

  // Initialize profiles toggle
  addMultipleEventListener(sidebarTrigger, ['click'], () => {
    toggleCSSclasses(sidebar, 'pointer-events-none', 'opacity-0'); 
    // Hide the menu if open
    // navbar.classList.remove('pointer-events-')
    menuToggleButton.classList.remove('is-active');
    navbar.classList.add('pointer-events-none');
    navbar.classList.add('opacity-0');
  });
}


const lazyImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));
let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
  entries.forEach(function(entry) {
    if (entry.isIntersecting) {
      let lazySource = entry.target.querySelector('source');
      let lazyImage = entry.target.querySelector('img'); 
      if (lazySource) {
        lazySource.srcset = lazySource.dataset.srcset;
      }
      if (lazyImage) {
        lazyImage.srcset = lazyImage.dataset.srcset;
      } else {
        entry.target.srcset = entry.target.dataset.srcset;
      }
      entry.target.classList.remove("lazy");
      lazyImageObserver.unobserve(entry.target);
    }
  });
  });

lazyImages.forEach(function(lazyImage) {
  lazyImageObserver.observe(lazyImage);
});

function registerDrawer(trigger) {
  //const drawer = trigger.nextElementSibling;
  addMultipleEventListener(trigger, ['click'], () => {
    toggleCSSclasses(trigger, 'active');
  });
}

function registerDrawers() {
  const drawers = document.querySelectorAll('.drawer');
  drawers.forEach((trigger) => {
    registerDrawer(trigger);
  });
}

registerDrawers();


document.addEventListener('htmx:afterOnLoad', (event) => {
  const drawers = event.target.querySelectorAll('.drawer');
  drawers.forEach((trigger) => {
    registerDrawer(trigger);
  })
});

// Handle district filter change
const districtFilter = document.querySelector('select[data-fui-id="clubRegistration-selectYourDistrict"]');
const clubsDropdown = document.querySelector('select[data-fui-id="clubRegistration-club"]');
if (districtFilter && clubsDropdown) {
  addMultipleEventListener(districtFilter, ['change'], () => {
    // console.info(districtFilter.value);
    const categoryId = districtFilter.value;
    fetch(`/actions/forms-handler/district-filter/get-clubs-by-district?categoryId=${categoryId}`)
      .then(response => response.json())
      .then(entries => {
        while(clubsDropdown.firstChild) {
          clubsDropdown.removeChild(clubsDropdown.firstChild);
        }
        entries.forEach(entry => {
          const optionElement = document.createElement('option');
          optionElement.text = entry.title;
          optionElement.value = entry.id;
          clubsDropdown.add(optionElement);
        });
      })
  });
}

const ageCalculations = document.querySelectorAll('[calculateage]');
ageCalculations.forEach((element) => {
  const source = document.querySelector('[name="' + element.getAttribute('calculateage') + '"]');
  source.addEventListener('change', () => {
    const birthday = source.value;
    // Calculate the age from the birthday in pure javascript
    const age = ~~((Date.now() - new Date(birthday).getTime()) / (31557600000));
    element.value = age;
    element.dispatchEvent(new InputEvent("input"));
  });
});

// Handle profile selection
// const profileButtons = document.querySelectorAll('[data-profile-target]');
// profileButtons.forEach((button) => {
//   button.addEventListener('click', (event) => {
//     event.preventDefault();
//     profileButtons.forEach((button) => {
//       button.classList.remove('active');
//     });
//     toggleCSSclasses(button, 'active');
//     const targetInput = document.querySelector(`[data-input=${button.dataset.profileTarget}]`);
//     console.info(targetInput);
//   });
// });

const checkboxes = document.querySelectorAll('.profile-select');
checkboxes.forEach(function(checkbox) {
    checkbox.addEventListener('change', function() {
        checkboxes.forEach(function(c) {
            if (c !== checkbox) c.checked = false;
        });
    });
});
